import React from "react"
import './Footer.scss'

const Footer = () => 
    <div className="FooterContainer"></div> 

export default Footer;



